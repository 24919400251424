import React from 'react'

import { Link } from 'gatsby'

import styled from 'styled-components'

import Layout from '../../components/layout'

import { MainSectionWrapper } from '../../components/common/styles'

import InstagramIcon from '../../components/icons_and_logos/instagram-icon'
import LinkedInIcon from '../../components/icons_and_logos/linkedin-icon'
import TwitterIcon from '../../components/icons_and_logos/twitter-icon';
import DribbbleIcon from '../../components/icons_and_logos/dribbble-icon';

const MainSectionWrapperOverride = styled(MainSectionWrapper)`
    padding-left: 80px;
    
    display: grid;
    place-items: center;
`

const Wrapper = styled.div`
    text-align: center;

    & > div {
        margin: 10px;
    }
`

const SocialContainer = styled.div`
    display: flex;
    justify-content: center;

    & > * {
        margin: 3px;
    }
`

const Contact = () => (
    <Layout>
        <MainSectionWrapperOverride>
            <Wrapper>
                <Link to="/contact/form">
                    <h1
                        style={{
                            color: '#5399b7',
                            cursor: 'pointer',
                            textTransform: 'uppercase',
                            marginBottom: '10px',
                            fontWeight: 900,
                        }}
                    >
                        Contact Form
                    </h1>
                </Link>
                <h3 style={{ fontWeight: 200 }}>valentinaramirezdesign@gmail.com</h3>
                <h4 style={{ fontWeight: 200 }}>or follow me:</h4>
                <SocialContainer>
                    <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/ramivdesign/"><InstagramIcon width={40} /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/in/valentina-ramirez-sanchez/"><LinkedInIcon width={40} /></a>
                    <a><TwitterIcon width={40} /></a>
                    <a><DribbbleIcon width={40} /></a>
                </SocialContainer>
            </Wrapper>
        </MainSectionWrapperOverride>
    </Layout>
)

export default Contact