import React from 'react'

const DribbbleIcon = props => (
  <svg viewBox="0 0 32.5 31.5" {...props}>
    <path
      d="M15.1 3.9h1.8c.2 0 .5.1.7.1 4.5.6 8.2 3.9 9.3 8.3.2.6.3 1.3.4 1.9v1.9c0 .1-.1.2-.1.3-.5 4.7-3.8 8.5-8.4 9.7-.6.1-1.2.2-1.7.4h-2c-.1 0-.1-.1-.2-.1-4.5-.6-7.6-2.9-9.3-7.1-.4-1-.6-2.2-.8-3.3v-1.7c0-.3.1-.5.1-.8C5.6 9 9 5.3 13.5 4.2c.5-.1 1.1-.2 1.6-.3zm-4.2 18.3c2.6 2.1 6.4 2.1 8.4 1.1-.6-2.1-1.1-4.2-1.7-6.4-3 1-5.1 2.8-6.7 5.3zm5.3-8.3c-3 .9-5.9 1.2-8.9 1.1 0 2.2.7 4.1 2.1 5.8 1.9-2.7 4.3-4.7 7.4-5.8-.2-.3-.4-.7-.6-1.1zM7.5 13c2.7.1 5.2-.2 7.9-1-1.1-1.6-2.1-3.2-3.1-4.8-2.5 1.3-4 3.2-4.8 5.8zm9.8-1.6c1.6-.7 3.1-1.6 4.2-3.1-2.2-1.7-4.6-2.2-7.3-1.7 1.1 1.6 2.1 3.1 3.1 4.8zm2.2 5.1c.5 2 1.1 3.9 1.6 5.9 1.9-1.4 3.1-3.3 3.6-5.6-1.8-.4-3.5-.6-5.2-.3zm-1.4-3.3c.2.4.3.7.4 1.1.1.2.2.3.5.3 1.1-.1 2.2-.1 3.2-.1.8 0 1.7.2 2.5.3.2-1.4-.9-4.1-1.9-4.9-1.2 1.4-2.8 2.5-4.7 3.3z"
      fill="#7fb6e0"
    />
  </svg>
)

export default DribbbleIcon

