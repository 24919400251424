import React from 'react'

const TwitterIcon = props => (
  <svg viewBox="0 0 32.5 31.5" {...props}>
    <path
      d="M3.4 29.3V4.6h24.7v24.7H3.4zm.1-4.6c0 .1 0 .1 0 0 .4.3.9.5 1.3.8 1.1.6 2.2.9 3.4 1.2 1.2.3 2.4.3 3.6.3 1.7-.1 3.4-.4 4.9-1 1.7-.7 3.2-1.7 4.5-3 .9-.9 1.7-2 2.3-3.1.6-1.1 1.1-2.2 1.4-3.4.4-1.4.6-2.8.5-4.3 0-.2 0-.3.2-.4.3-.2.5-.4.8-.6.6-.5 1.1-1.1 1.6-1.8-.9.4-1.8.7-2.8.7 1-.7 1.7-1.5 2.1-2.7h-.1c-.1 0-.2.1-.2.1-.8.5-1.8.8-2.7 1-.2 0-.2 0-.3-.1-.7-.7-1.5-1.2-2.5-1.3-1.7-.3-3.2.1-4.4 1.2-1.1 1.1-1.7 2.4-1.6 4 0 .3.1.6.1.9-4.2-.3-7.7-2.1-10.4-5.3-1.2 2.7-.7 4.9 1.5 6.8-.8 0-1.5-.3-2.2-.6.1 2.6 1.5 4.2 3.9 5-.4.1-.7.1-1.1.1H6.2c.8 2.1 2.4 3.3 4.7 3.5-2.2 1.6-4.7 2.3-7.4 2z"
      fill="#fff"
    />
    <path
      d="M3.5 24.7c2.7.2 5.2-.4 7.4-2.1-2.3-.2-3.9-1.4-4.7-3.5.4 0 .8.1 1.1 0 .4 0 .7-.1 1.1-.1-2.5-.8-3.8-2.4-3.9-5 .7.3 1.4.6 2.2.6-2.2-1.9-2.7-4.1-1.5-6.8 2.8 3.2 6.2 5 10.4 5.3 0-.3-.1-.6-.1-.9-.1-1.6.5-2.9 1.6-4C18.4 7 19.9 6.6 21.5 7c1 .2 1.8.6 2.5 1.3.1.1.2.1.3.1.9-.2 1.8-.5 2.7-1 .1 0 .2-.1.2-.1h.1c-.4 1.1-1.1 2-2.1 2.7 1-.1 1.8-.4 2.8-.7-.5.7-1 1.3-1.6 1.8-.2.2-.5.4-.8.6-.1.1-.2.2-.2.4.1 1.4-.1 2.9-.5 4.3-.3 1.2-.8 2.3-1.4 3.4-.6 1.2-1.3 2.2-2.3 3.2-1.3 1.3-2.8 2.3-4.5 3-1.6.6-3.2 1-4.9 1-1.2 0-2.4 0-3.6-.3-1.2-.3-2.3-.6-3.4-1.2-.4-.2-.9-.5-1.3-.8 0 .1 0 .1 0 0z"
      fill="#8ec6db"
    />
  </svg>
)

export default TwitterIcon

